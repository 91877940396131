:root {
    --color-background-any: white;

    --color-ai-transparent-10: var(--color-violet-10p);
    --color-ai-transparent-20: var(--color-violet-20p);
    --color-ai-subtle: var(--color-violet-050);
    --color-ai-lighter: var(--color-violet-100);
    --color-ai-light: var(--color-violet-400);
    --color-ai-default: var(--color-violet-500);
    --color-ai-dark: var(--color-violet-700);
    --color-ai-darker: var(--color-violet-800);

    --color-brand-transparent-10: var(--color-reflex-10p);
    --color-brand-transparent-20: var(--color-reflex-20p);
    --color-brand-subtle: var(--color-reflex-050);
    --color-brand-lighter: var(--color-reflex-100);
    --color-brand-light: var(--color-reflex-400);
    --color-brand-default: var(--color-reflex-500);
    --color-brand-dark: var(--color-reflex-600);
    --color-brand-darker: var(--color-reflex-800);

    --color-neutral-negative: #fffffffa;
    --color-neutral-negative-light: #ffffff4d;
    --color-neutral-transparent-10: var(--color-gray-10p);
    --color-neutral-transparent-20: var(--color-gray-20p);
    --color-neutral-transparent-40: var(--color-gray-40p);
    --color-neutral-subtle: var(--color-gray-050);
    --color-neutral-lighter: var(--color-gray-100);
    --color-neutral-light: var(--color-gray-300);
    --color-neutral-default: var(--color-gray-500);
    --color-neutral-dark: var(--color-gray-700);
    --color-neutral-darker: var(--color-gray-800);
    --color-neutral-darkest: var(--color-gray-900);

    --color-destructive-transparent-10: var(--color-magenta-10p);
    --color-destructive-transparent-20: var(--color-magenta-20p);
    --color-destructive-subtle: var(--color-magenta-050);
    --color-destructive-lighter: var(--color-magenta-100);
    --color-destructive-light: var(--color-magenta-400);
    --color-destructive-default: var(--color-magenta-500);
    --color-destructive-dark: var(--color-magenta-700);
    --color-destructive-darker: var(--color-magenta-800);

    --color-success-transparent-10: var(--color-grass-10p);
    --color-success-transparent-20: var(--color-grass-20p);
    --color-success-subtle: var(--color-grass-050);
    --color-success-lighter: var(--color-grass-100);
    --color-success-light: var(--color-grass-400);
    --color-success-default: var(--color-grass-500);
    --color-success-dark: var(--color-grass-700);
    --color-success-darker: var(--color-grass-800);

    --radius-rd-xs: var(--pixels-none);
    --radius-rd-s: var(--pixels-02);
    --radius-rd-sm: var(--pixels-04);
    --radius-rd-m: var(--pixels-06);
    --radius-rd-l: var(--pixels-08);
    --radius-rd-xl: var(--pixels-12);
    --radius-rd-xxl: var(--pixels-16);
    --radius-round: var(--pixels-round);

    --padding-pd-xs: var(--pixels-02);
    --padding-pd-s: var(--pixels-04);
    --padding-pd-sm: var(--pixels-06);
    --padding-pd-m: var(--pixels-08);
    --padding-pd-l: var(--pixels-12);
    --padding-pd-xl: var(--pixels-16);
    --padding-pd-xxl: var(--pixels-24);
    --padding-pd-xxl-2: var(--pixels-32);

    --gap-gp-xs: var(--pixels-02);
    --gap-gp-s: var(--pixels-04);
    --gap-gp-m: var(--pixels-08);
    --gap-gp-l: var(--pixels-12);
    --gap-gp-xl: var(--pixels-16);
    --gap-gp-xxl: var(--pixels-24);

    --icon-size-ic-xs: var(--pixels-08);
    --icon-size-ic-s: var(--pixels-12);
    --icon-size-ic-m: var(--pixels-16);
    --icon-size-ic-l: var(--pixels-24);

    --color-info-transparent-10: var(--color-reflex-10p);
    --color-info-transparent-20: var(--color-reflex-20p);
    --color-info-subtle: var(--color-reflex-050);
    --color-info-subtle-2: var(--color-reflex-100);
    --color-info-light: var(--color-reflex-400);
    --color-info-default: var(--color-reflex-500);
    --color-info-dark: var(--color-reflex-700);
    --color-info-darker: var(--color-reflex-800);

    --color-warning-transparent-10: var(--color-orange-10p);
    --color-warning-transparent-20: var(--color-orange-20p);
    --color-warning-subtle: var(--color-orange-050);
    --color-warning-lighter: var(--color-orange-100);
    --color-warning-light: var(--color-orange-400);
    --color-warning-default: var(--color-orange-500);
    --color-warning-dark: var(--color-orange-700);
    --color-warning-darker: var(--color-orange-800);

    --border-color-hover-default: var(--color-neutral-light);
    --border-color-resting-default: var(--color-neutral-lighter);
    --border-radius-default: var(--radius-rd-sm);
    --border-width-bd-s: var(--pixels-01);
    --border-width-bd-m: var(--pixels-02);
    --border-width-bd-l: var(--pixels-04);

    --transparency-background-hover: 90%;
    --transparency-background-whilepressing: 80%;
    --shadow-neutral: 0px 0px 14px 0px rgba(1, 30, 183, 0.1);
}
